<template>
    <div class="main-container">
        <!-- SECTION TITLE -->
        <section class="section-title">
            <div class="title-container">
                <div class="col-img">
                    <img src="./../assets/banner-visitors.png" alt="banner visitors" />
                </div>
                <div class="col-title">
                    <h1>Visitors</h1>
                    <p><strong>Experience Matters!</strong><br /><br />For free, discover technical innovations, learn about Blockchain, connect with your peers and exchange with potential partners; everything that you do in a traditional convention can now be achieved in the comfort and safety of your office or home.<br /><br /> CryptoVirtua provides a new interactive collaborative network to Blockchain & Crypto experts around the world.</p>
                    <a class="btn" href="https://cryptovirtua.my-virtual-convention.com/register" target="_blank">Free Registration</a>
                </div>
            </div>
        </section>
        <!-- SECTION WHY COME -->
        <section class="section-why">
            <h2>Why Become an Attendee?</h2>
            <div class="list-why-container">
                <ul class="list-why">
                    <li>Interact with leading crypto & blockchain projects</li>
                    <li>Follow industry trends & innovations</li>
                    <li>Identify the best companies of the crypto & blockchain ecosystem</li>
                    <li>Explore new DeFi protocols, GameFi & Metaverse projects</li>
                    <li>Discover the trendy NFT creators & collections</li>
                    <li>Network with experts, partners, developers, investors & key industry players</li>
                    <li>Raise funds for your project & innovation</li>
                    <li>Meet global crypto & blockchain influencers</li>
                    <li>Learn from the pioneers & key opinion leaders</li>
                    <li>Hear experts, investors and founders discuss the latest trends in blockchain technology, crypto, Web 3.0, NFT, business and fundraising</li>
                    <li>Learn about new startups that promise to disrupt incumbent technologies</li>
                    <li>Understand blockchain impact on legal, fintech, finance, government, banking, energy, manufacturing, industrial, automotive, real estate, Web</li>
                    <li>Overview of how cryptos are valued, traded, organized, used for commerce, managed, etc.</li>
                    <li>Learn how to trade crypto and what are the best practices</li>
                    <li>Meet with Institutional Investors, Family Offices, Hedge Funds, Asset Allocators & Angel Investors who are on the lookout for the most promising entrepreneurial projects in the crypto and blockchain industry</li>
                </ul>
            </div>
        </section>
        <!-- SECTION WHO -->
        <section class="section-who">
            <h2>What Kinds of Companies are Attending the Conference?</h2>
            <div class="who-container">
                <div class="col-who">
                    <ul>
                        <li>Blockchain Companies / Foundations / Ecosystems</li>
                        <li>DeFi Key Players</li>
                        <li>Exchanges <span class="list-light">(DEX, CEX, etc.)</span></li>
                        <li>Gaming & GameFi Ecosystem</li>
                        <li>Hardware Providers <span class="list-light">(Mining, ASIC, etc.)</span></li>
                        <li>Incubators</li>
                        <li>Blockchain Innovative Start-ups</li>
                    </ul>
                </div>
                <div class="col-who">
                    <ul>
                        <li>Investments Companies  <span class="list-light">(Staking, Cloud mining, Masternodes, etc.)</span></li>
                        <li>Launchpads</li>
                        <li>Metaverse Main Actors</li>
                        <li>NFT Creators</li>
                        <li>NFT Marketplaces</li>
                        <li>Software & Tools Providers</li>
                    </ul>
                </div>
            </div>
            <div class="actions-container">
                <div class="actions-col">
                    <h3>REGISTER FOR THE EVENT</h3>
                    <a href="https://cryptovirtua.my-virtual-convention.com/register"  target="_blank" class="actions-btn">Register</a>
                </div>
                <div class="actions-col">
                    <h3>ALREADY HAVE AN ACCOUNT?</h3>
                    <a href="https://cryptovirtua.my-virtual-convention.com/login" target="_blank" class="actions-btn actions-login">Log in</a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name : 'Visitors',
    metaInfo: {
        title: 'Visitors | Connect With Your Peers',
        meta: [
            { name: 'description', content: 'Attend the Ortho Digital Symposium and stay connected with your industry markets and professionals around the world.' },
        ]
    },
}
</script>

<style lang="scss" scoped>
    .section-title {
        margin-top:150px;
        padding:80px 0;
        background-image: url('./../assets/bg_visitor.jpg');
        background-position: center right;
        background-repeat: no-repeat;
    }

    .main-container {
        margin-top: -80px;
    }

    .title-container {
        width:$box;
        margin:0 auto;
        max-width: 90%;
        display: flex;
        align-items: center;
    }

    .col-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width:50%;
    }

    .col-img img {
        max-width: 90%;
    }

    .col-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
    }

    .col-title h1 {
        color:$main-color;
        font-size:40px;
        font-weight:700;
        padding: 0;
        margin: 0 0 20px 0;
        text-align: center;
    }

    .col-title p {
        color:$text-color;
        text-align: center;
        font-size:18px;
        line-height: 1.5em;
        max-width: 600px;
        margin: 0;
    }

    .col-title p strong {
        font-size:25px;
    }

    .section-why {
        padding:80px 0;
        background-image: url('./../assets/bg-why-exhibit.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }

    .why-sep-top {
        position: absolute;
        top:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-top.png');
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .why-sep-bottom {
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-bottom.png');
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .list-why-container {
        width:$box;
        margin:0 auto;
        max-width: 90%;
        display: flex;
        align-items: center;
    }

    .list-why {
        max-width: 80%;
        margin: 40px auto 0 auto;
        padding:0;
    }

    .list-why li {
        color:$secondary-color;
        font-size:20px;
        margin-bottom:20px;
        list-style: none;
        position: relative;
        left:  40px;
        font-weight:600;
        margin-right: 20px;
    }

    .list-why li::before {
        content:'';
        width:25px;
        height:20px;
        background-image: url('./../assets/puce.png');
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        left:-40px;
        top:3px;
    }

    .section-who {
        padding:80px 0;
        background-image: url('./../assets/bg-home-participate.png');
        background-position: center right;
        background-repeat: no-repeat;
    }

    .section-who h2 {
        color:$main-color;
    }

    .who-container {
        width:$box;
        margin:80px auto;
        max-width: 90%;
        display: flex;
    }

    .col-who {
        width:44%;
        margin:0 3%;
    }

    .col-who li {
        color:$main-color;
        font-size:20px;
        margin-bottom:20px;
        list-style: none;
        position: relative;
        left:  40px;
        font-weight:600;
        margin-right: 20px;
    }

    .list-light {
        font-weight:400;
    }

    .col-who li::before {
        content:'';
        width:25px;
        height:22px;
        background-image: url('./../assets/puce-blue.png');
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        left:-40px;
        top:3px;
    }

    @media screen and (max-width:768px) {
        .col-title h1 {
            font-size:30px
        }   
        
        .col-title p {
            font-size:20px;
        }

        .title-container {
            flex-direction: column;
        }

        .col-img, .col-title {
            width:96%;
        }

        .section-title, .section-why {
            padding:40px 0;
        }

        .list-why li, .col-who li {
            font-size:18px;
            left:20px;
        }

        .section-who {
            padding:40px 0; 
        }

        .who-container {
            flex-direction: column;
        }

        .col-who {
            width:100%;
        }

        .main-container {
            margin-top: -20px;
        }

    }
</style>